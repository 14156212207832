<template>
  <div class="inspectionRecords-home">
    <div class="topHeader">
      <div>
        <div class="queryItem">
          <span class="queryItemTitle">当前比对周期：</span>
          <span style="font-weight: bold">{{ stage }}</span>
        </div>
        <div class="queryItem">
          <span class="queryItemTitle">随访周期：</span>
          <el-checkbox-group v-model="queryExamDate" @change="changeQueryExamDate">
            <el-checkbox v-for="(item, index) in dateList" :key="index" :label="item.dataId">
              {{ item.menuTitle }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="queryItem">
          <span class="queryItemTitle">刷选规则：</span>
          <el-checkbox-group
            style="display: flex"
            v-model="selectRules"
            @change="changeSelectRules"
          >
            <el-checkbox label="">显示全部</el-checkbox>
            <el-checkbox label="1">仅显示差异项</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div>
        <el-button class="btn-back" size="small" type="primary" @click="goBack" icon="el-icon-back">
          返回
        </el-button>
      </div>
    </div>
    <div class="inspectionRecords-Box">
      <el-tabs type="card" v-model="activeName">
        <el-tab-pane
          v-for="(ele, index) in queryExamList"
          :key="ele.index"
          :label="ele.table"
          :name="String(index)"
        >
          <table class="inspectionRecords-table">
            <thead>
              <tr>
                <th style="z-index: 99"></th>
                <th v-for="(item, idx) in ele.headers" :key="idx">
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(rowsItem, rowsIndex) in ele.rows" :key="rowsIndex">
                <td>
                  <div class="leftTitleContent">
                    <div class="leftItemTitle">
                      {{ rowsItem.title }}
                    </div>
                    <!-- <div class="leftEleTitle">
                      <div class="leftEleTitleItem" @click="handleExamineTitle(rowsItem)">
                        <img class="foldLineImg" :src="foldLineImg" alt="" />
                      </div>
                    </div> -->
                  </div>
                </td>
                <td v-for="(colsItem, colsIdx) in rowsItem.cols" :key="colsIdx">
                  <span style="color: red" v-if="colsItem.upDown == 1">
                    {{ colsItem.value + '↑' }}
                  </span>
                  <span style="color: #2ecc71" v-else-if="colsItem.upDown == -1">
                    {{ colsItem.value + '↓' }}
                  </span>
                  <span v-else>{{ colsItem.value }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty
            style="text-align: center"
            v-if="!ele.rows?.length"
            description="暂无数据"
          ></el-empty>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-empty
      style="text-align: center"
      v-if="!queryExamList?.length"
      description="暂无数据"
    ></el-empty>
    <el-dialog width="50%" title="变化趋势" :visible.sync="examineInnerVisible">
      <el-card
        shadow="never"
        class="chartInfoOpen"
        :body-style="{ padding: '0.625rem 0.9375rem 0' }"
        v-if="examRecordTrendList?.length !== 0"
      >
        <div class="lineChart" id="lineChart"></div>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { getFormatDate } from '../../../../utils'

export default {
  name: 'followComparison',
  data() {
    return {
      examineInnerVisible: false,
      queryExamList: [],
      examRecordTrendList: [],
      foldLineImg: require('@/assets/img/patientCenter/foldLine.png'),
      activeName: '',

      examineInfo: {
        studyStartDate: '',
        studyEndDate: ''
      },
      selectRules: [''],
      chartsTitle: '',
      queryRules: '',
      dateList: [],
      queryExamDate: [],
      examDate: '',
      operationId: undefined,
      moduleCode: undefined,
      baseFollowId: undefined,
      stage: undefined
    }
  },
  created() {
    this.operationId = this.$route.query.operationId
    this.moduleCode = this.$route.query.moduleCode
    this.stage = this.$route.query.stage
  },
  mounted() {
    this.getFollowRecordList()
  },
  methods: {
    getFormatDate,
    async getFollowRecordList() {
      if (!this.operationId || !this.moduleCode) {
        return this.$message.error('参数错误')
      }

      const res = await this.$api.get(
        `/v1/webconsole/follow/menu/${this.operationId}/${this.moduleCode}`
      )

      res.data.data.forEach((item) => {
        if (item.menuTitle === '随访记录') {
          for (let i = 0; i < item.subMenus.length; i++) {
            const element = item.subMenus[i]
            if (element.menuTitle === this.stage) {
              this.baseFollowId = element.dataId
            }
          }
          this.dateList = item.subMenus.filter((ele) => ele.dataId !== this.baseFollowId)
        }
      })

      this.queryExamDate = this.dateList.map((item) => item.dataId)
      this.examDate = this.queryExamDate.join(',')

      this.queryInfo()
    },
    changeQueryExamDate() {
      if (this.queryExamDate && this.queryExamDate.length > 0) {
        if (this.queryExamDate.length > 1) {
          this.examDate = this.queryExamDate.join(',')
        } else {
          this.examDate = this.queryExamDate[0]
        }
      } else {
        this.examDate = null
      }
      this.queryInfo()
    },
    goBack() {
      this.$router.back()
    },

    handleExamineTitle(val) {
      this.examRecordTrendList = val.cols
      this.examineInnerVisible = true
      this.chartsTitle = val.title
      this.$nextTick(() => {
        this.draw_examLine()
      })
    },
    draw_examLine() {
      let examRecordTrendList = this.examRecordTrendList
      var lineCharts = document.getElementById('lineChart')
      // 循环元素
      let xAxisData = []
      let yAxisData = []
      let averageValue = ''
      let valueLength = null
      let decimal_point_index = 1

      examRecordTrendList.forEach((item) => {
        if (item.value && item.time) {
          xAxisData.push(item.time)
        }
        // y轴数据为空时 图表不展示
        if (item.value) {
          yAxisData.push(item.value)
        }
        if (!valueLength) {
          valueLength = item.value
        }
      })

      if (examRecordTrendList.length > 0) {
        averageValue = examRecordTrendList[0]?.value
      }
      if (valueLength > 1) {
        decimal_point_index = 1
      } else {
        decimal_point_index = 1 / 10 ** valueLength?.toString().split('.').pop().length
      }
      if (this.lineChart != null && this.lineChart != '' && this.lineChart != undefined) {
        this.lineChart.dispose() // 销毁
      }
      this.lineChart = echarts.init(lineCharts) // 绘制图表
      var Option = {
        title: {
          text: this.chartsTitle
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisData
        },
        yAxis: {
          type: 'value',
          minInterval: decimal_point_index
        },
        series: [
          {
            data: yAxisData,
            type: 'line',
            markLine: {
              symbol: ['none', 'none'], // 去掉箭头
              data: [
                {
                  name: '基线',
                  yAxis: averageValue
                }
              ],
              lineStyle: {
                color: '#f00'
              }
            }
          }
        ]
      }
      this.lineChart.setOption(Option)
    },
    queryInfo() {
      if (!this.examDate) {
        return this.$message.error('至少选择一项周期')
      }
      if (!this.baseFollowId) {
        return this.$message.error('参数错误')
      }

      let params = new FormData()

      params.append('followIds', this.examDate)
      params.append('filter', this.queryRules)

      this.$api.post(`/v1/webconsole/follow/comparison/${this.baseFollowId}`, params).then(
        (res) => {
          if (res.data && res.data.data) {
            this.queryExamList = res.data.data
          }
        },
        () => {}
      )
    },
    changeSelectRules() {
      if (this.selectRules && this.selectRules.length > 1) {
        this.selectRules = this.selectRules.slice(-1)
        this.queryRules = this.selectRules[0]
        this.queryInfo()
      } else {
        this.selectRules = [...this.queryRules]
      }
    }
  }
}
</script>

<style lang="less" scoped>
.chartInfoOpen {
  width: 100%;
  .lineChart {
    width: 100%;
    height: 320px;
  }
}
.inspectionRecords-home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .topHeader {
    display: flex;
    justify-content: space-between;
    box-shadow: 0 2px 2px #ccc;
    padding: 10px 20px;
    // height: 32px;
    position: relative;

    .input-form-item {
      display: flex;
      align-items: center;
      .input-form-item-label {
        font-size: 18px;
      }
    }

    .queryItem {
      margin: 10px 0;
      font-size: 18px;
      display: flex;
      align-items: center;
      .queryItemTitle {
        white-space: nowrap;
      }
    }
  }

  /deep/ .inspectionRecords-Box::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  /deep/ .inspectionRecords-Box::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  /deep/ .inspectionRecords-Box::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }
  /deep/ .inspectionRecords-Box::-webkit-scrollbar-thumb:window-inactive {
    background: #ccc;
  }
  .info-content {
    margin-top: 10px;
    padding: 0 20px;
    .examinationItem {
      display: flex;
      align-items: center;
      .examinationItemTitle {
        width: 160px;
      }
      .examinationItemValue {
        height: 28px;
        line-height: 28px;
      }
    }
    .el-divider__text {
      background-color: #f8f8f8;
    }
    .el-row {
      margin-bottom: 15px;
    }
    .el-input {
      width: 250px;
    }
    .el-input__inner {
      border-color: #dcdfe6;
      height: 35px;
    }
    .el-tabs__header {
      position: fixed;
      z-index: 100;
      background: #fff;
    }
    .el-tabs__content {
      .container {
        margin-top: 80px;
      }
    }
  }
  .inspectionRecords-Box {
    display: flex;
    // height: calc(100% - 100px);
    overflow: auto;
    .inspectionRecords-table {
      border-collapse: collapse;
      width: 100%;
      border: 1px solid #dddddd;
      /* 表格th/td样式 */
      td,
      th {
        width: 150px;
        box-sizing: border-box;
        border-right: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        /*超出长度...*/
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 30px;
      }

      /* 表头固定 */
      thead tr th {
        position: sticky;
        top: 0;
        background: #f4f4f5;
        height: 30px;
      }

      /* 首列固定/最后一列固定*/
      th:first-child,
      td:first-child {
        position: sticky;
        left: 0;
        background: #f4f4f5;
        text-align: center;
        right: 0px;
        border-left: 1px solid #dddddd;
        width: 100px;
      }

      /* 表头首列和最后一列强制最顶层 */
      th:last-child {
        z-index: 3;
        /*左上角单元格z-index，切记要设置，不然表格纵向横向滚动时会被该单元格右方或者下方的单元格遮挡*/
        background: #f4f4f5;
      }
    }

    .inspectionRecords-table th,
    .inspectionRecords-table td {
      white-space: nowrap;
      border: 1px solid #ddd;
      padding: 8px;
      text-align: center;
    }

    .inspectionRecords-table {
      .leftTitleContent {
        display: flex;
        .leftItemTitle {
          font-weight: bold;
          float: left;
        }
        .leftEleTitle {
          padding-left: 12px;
          cursor: pointer;
          float: right;
          .leftEleTitleItem {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .foldLineImg {
            width: 18px;
            height: 18px;
            padding-right: 10px;
          }
        }
      }
    }
  }
}
</style>
